<template>
    <div>
        <div class="container-fluid ">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-8 col-lg-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-12 col-lg-8 fw-bold text-center text-darkblue my-4">
                        COMPANY LIQUIDATION AND DISSOLUTION
                    </h1>
                    <div class="col-12 col-lg-7 mb-5">
                        <div class="col-9 border border-blue p-4 my-4 rounded-3">
                            You need to close down your company and need assistance in the dissolution process, yet avoiding a lengthy and costly procedure ?
                        </div>
                        <div class="col-10 h5 fw-light bg-navyblue p-4 mt-5 mb-4 ms-auto text-end rounded-3">
                            We will assist you throughout all legal steps that should be followed, and advise how to minimize the liabilities of your company and its directors
                        </div>
                    </div>
                    <div class="col-12 col-lg-8">
                        <p class="fw-light mb-5">
                            The dissolution of a Thailand company is a lengthy process that requires approval from both the Ministry of Commerce (MOC) and Revenue Department. After registering the dissolution, the Revenue Department will conduct a full scale tax audit of the company. The Revenue Department will not approve the dissolution until all tax liabilities and fines have been paid. Thus, registering a dissolution can take from 6 months to 1 year due to the time it takes to complete the tax audit.
                        </p>
                        <h5 class="fw-bold mb-4">
                            Our dissolution service includes the following:
                        </h5>
                        <ul>
                            <li v-for="(service, index) in services" :key="index" class="py-1">
                                {{ service }}
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.price-card {
    border-color: var(--royal);
    border-top: 12px solid var(--royal);
}
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Ffolder_files.svg?alt=media&token=ed9f7206-093e-4131-bb3d-e75e2b4e09f0"
            },
            services: [
                "Provide consulting in regard to liquidation procedures.",
                "Prepare dissolution registration and supporting documents.",
                "Submit the application to the Ministry of Commerce (MOC).",
                "Prepare documents and assist with deregistration of VAT.",
                "Obtain Certificate of Dissolution from the MOC."
            ]
        }
    }
}
</script>